import React from 'react';
import { Card, Image, Header, Flag, Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import CardButton from './CardButton';

const ProductCard = ({ 
  id,
  image,
  headerKey,
  descriptionHeaderKey,
  metaKey,
  descriptionTextKey,
  flags = [],
  isSelected,
  onSelect,
  onContinue,
  hoverProps 
}) => {
  const { t } = useTranslation();
  
  return (
    <Card 
      {...hoverProps} 
      id={isSelected ? "card-selected" : undefined} 
      onClick={() => onSelect(id)}
    >
      <Image src={image} />
      <Card.Content>
        <Card.Header>
          <Header id={isSelected ? 'card-selected' : false}>
            {t(headerKey)}
          </Header>
        </Card.Header>
        <Card.Description id={isSelected ? 'card-selected' : false}>
          {t(descriptionHeaderKey)}
        </Card.Description>
        <Card.Meta id={isSelected ? 'card-selected' : false}>
          {t(metaKey)} {flags.map(flag => <Flag key={flag} name={flag} />)}
        </Card.Meta>
        <Card.Description id={isSelected ? 'card-selected' : false}>
          {isSelected ? (
            <CardButton onClick={onContinue} text={t('continue')} />
          ) : (
            <Container textAlign='justified'>
              {t(descriptionTextKey)}
            </Container>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

export default ProductCard; 