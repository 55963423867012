import React, { useState, useCallback } from 'react';
import { Card, Button, Divider, Label, Step, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useHover from 'react-use-hover';
import ProductCard from './ProductCard';
import { getProductsByCategory } from '../data/products';

const ProductSelection = ({ 
  category, 
  onContinue, 
  deviceMobile
}) => {
  const { t } = useTranslation();
  const products = getProductsByCategory(category);
  const [selectedProductId, setSelectedProductId] = useState(null);
  
  // Create hover hooks for all possible products
  const hoverHooks = {};
  for (let i = 1; i <= 11; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isHovering, hoverProps] = useHover();
    hoverHooks[i] = { isHovering, hoverProps };
  }
  
  const handleProductSelect = useCallback((id) => {
    setSelectedProductId(id);
  }, []);
  
  const handleContinue = useCallback(() => {
    if (selectedProductId) {
      const selectedProduct = products.find(p => p.id === selectedProductId);
      onContinue(selectedProduct.name);
    }
  }, [selectedProductId, products, onContinue]);

  const getCategoryLabel = useCallback(() => {
    return <Label attached='top left' size='large' color='orange'>{category.charAt(0).toUpperCase() + category.slice(1)}</Label>;
  }, [category]);

  return (
    <div className='Container_Modal'>
      {deviceMobile ? 
        <Step.Group unstackable widths={3} size='mini'>
          <Step active>
            <Icon name='gift' color='orange'/>
          </Step>
          <Step disabled>
            <Icon name='truck' color='orange'/>
          </Step>
          <Step disabled>
            <Icon name='info' color='orange'/>
          </Step>
        </Step.Group> : 
        <Step.Group unstackable widths={3} size='mini'>
          <Step active>
            <Icon name='gift' color='orange'/>
            <Step.Content>
              <Step.Title>1. {t('choose_present')}</Step.Title>
            </Step.Content>
          </Step>
          <Step disabled>
            <Icon name='truck' color='orange'/>
            <Step.Content>
              <Step.Title>2. {t('delivery_details')}</Step.Title>
            </Step.Content>
          </Step>
          <Step disabled>
            <Icon name='info' color='orange'/>
            <Step.Content>
              <Step.Title>3. {t('confirmation')}</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      }

      <Divider />

      <Card.Group 
        style={deviceMobile || category === 'standard' ? {} : {overflow: 'auto', maxHeight: 610 }} 
        itemsPerRow={4} 
        centered 
        doubling 
        stackable
      >
        {products.map(product => (
          <ProductCard
            key={product.id}
            id={product.id}
            image={product.image}
            headerKey={product.headerKey}
            descriptionHeaderKey={product.descriptionHeaderKey}
            metaKey={product.metaKey}
            descriptionTextKey={product.descriptionTextKey}
            flags={product.flags}
            isSelected={selectedProductId === product.id}
            onSelect={handleProductSelect}
            onContinue={handleContinue}
            hoverProps={hoverHooks[product.id].hoverProps}
          />
        ))}
      </Card.Group>

      <Divider />

      {getCategoryLabel()}

      <div className='Container_small_right'>
        <Button 
          positive 
          disabled={!selectedProductId}
          onClick={handleContinue}
        >
          {t('continue')}
        </Button>
      </div>
    </div>
  );
};

export default ProductSelection; 