import React from 'react';
//import ReactDOM from 'react-dom/client';
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { useTranslation } from "react-i18next"

import './index.css';
import RedeemCode from './App';
import BuyCode from './BuyCode';
import Imprint from './Imprint';
import Terms from './Terms';
import Copyright from './Copyright';
import DataProtection from './DataProtection';
import HowItWorks from './HowItWorks';
import AutomaticCodeRedeem from './pages/AutomaticCodeRedeem';
import BuyerInfo from './pages/BuyerInfo';
import CookieBanner from './CookieBanner';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'de'],
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  });

export default function App() {
  const { t } = useTranslation()

  const cookieTranslations = {
    cookie_title: t('cookie_title'),
    cookie_description: t('cookie_description'),
    cookie_customize: t('cookie_customize'),
    cookie_accept_all: t('cookie_accept_all'),
    cookie_settings: t('cookie_settings'),
    cookie_necessary: t('cookie_necessary'),
    cookie_necessary_description: t('cookie_necessary_description'),
    cookie_analytics: t('cookie_analytics'),
    cookie_analytics_description: t('cookie_analytics_description'),
    cookie_marketing: t('cookie_marketing'),
    cookie_marketing_description: t('cookie_marketing_description'),
    back: t('back'),
    cookie_save_settings: t('cookie_save_settings')
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<BuyCode />} />
        <Route path='/order/:code/:name?' element={<BuyerInfo />} /> 
        <Route path='/:code' element={<AutomaticCodeRedeem />} />
        <Route path='/redeem' element={<RedeemCode />} />
        <Route path='/how_it_works' element={<HowItWorks />} />
        <Route path='/imprint' element={<Imprint />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/copyright' element={<Copyright />} />
        <Route path='/data_protection' element={<DataProtection />} />
      </Routes>
      <CookieBanner translations={cookieTranslations} />
    </BrowserRouter>
  )
}

const root = document.getElementById("root");
render(<App />, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
