const standardProducts = [
  {
    id: 1,
    name: "Chocodrink",
    image: "/images/chocodrink_standard.jpg",
    headerKey: "standard_chocodrink_card_header",
    descriptionHeaderKey: "standard_chocodrink_card_description_header",
    metaKey: "standard_chocodrink_card_meta",
    descriptionTextKey: "standard_chocodrink_card_description_text",
    flags: ["fr"]
  },
  {
    id: 2,
    name: "Teeflower",
    image: "/images/tea_standard.jpg",
    headerKey: "standard_tee_card_header",
    descriptionHeaderKey: "standard_tee_card_description_header",
    metaKey: "standard_tee_card_meta",
    descriptionTextKey: "standard_tee_card_description_text",
    flags: ["cn"]
  },
  {
    id: 3,
    name: "Magnetic Cube",
    image: "/images/magnets_standard.jpg",
    headerKey: "standard_magnet_card_header",
    descriptionHeaderKey: "standard_magnet_card_description_header",
    metaKey: "standard_magnet_card_meta",
    descriptionTextKey: "standard_magnet_card_description_text",
    flags: ["de"]
  },
  {
    id: 4,
    name: "Licorice",
    image: "/images/licorice_standard.jpg",
    headerKey: "standard_licorice_card_header",
    descriptionHeaderKey: "standard_licorice_card_description_header",
    metaKey: "standard_licorice_card_meta",
    descriptionTextKey: "standard_licorice_card_description_text",
    flags: ["dk", "it"]
  }
];

const premiumProducts = [
  ...standardProducts,
  {
    id: 5,
    name: "Balsamico and Olive oil",
    image: "/images/balsamico-olive_premium.jpg",
    headerKey: "premium_balsamico_card_header",
    descriptionHeaderKey: "premium_balsamico_card_description_header",
    metaKey: "premium_balsamico_card_meta",
    descriptionTextKey: "premium_balsamico_card_description_text",
    flags: ["gr", "it"]
  },
  {
    id: 6,
    name: "Pen",
    image: "/images/parker_premium.jpg",
    headerKey: "premium_pen_card_header",
    descriptionHeaderKey: "premium_pen_card_description_header",
    metaKey: "premium_pen_card_meta",
    descriptionTextKey: "premium_pen_card_description_text",
    flags: ["fr"]
  },
  {
    id: 7,
    name: "Coffee",
    image: "/images/coffee_premium.jpg",
    headerKey: "premium_coffee_card_header",
    descriptionHeaderKey: "premium_coffee_card_description_header",
    metaKey: "premium_coffee_card_meta",
    descriptionTextKey: "premium_coffee_card_description_text",
    flags: ["pa"]
  },
  {
    id: 8,
    name: "Lindt Chocolate",
    image: "/images/chocolate_premium.jpg",
    headerKey: "premium_choco_card_header",
    descriptionHeaderKey: "premium_choco_card_description_header",
    metaKey: "premium_choco_card_meta",
    descriptionTextKey: "premium_choco_card_description_text",
    flags: ["de"]
  }
];

const exclusiveProducts = [
  ...premiumProducts,
  {
    id: 9,
    name: "Wine",
    image: "/images/wine_exclusive.jpg",
    headerKey: "exclusive_wine_card_header",
    descriptionHeaderKey: "exclusive_wine_card_description_header",
    metaKey: "exclusive_wine_card_meta",
    descriptionTextKey: "exclusive_wine_card_description_text",
    flags: ["it"]
  },
  {
    id: 10,
    name: "Zipper",
    image: "/images/zippo_exclusive.jpg",
    headerKey: "exclusive_zipper_card_header",
    descriptionHeaderKey: "exclusive_zipper_card_description_header",
    metaKey: "exclusive_zipper_card_meta",
    descriptionTextKey: "exclusive_zipper_card_description_text",
    flags: ["us"]
  },
  {
    id: 11,
    name: "Knife",
    image: "/images/knife_exclusive.jpg",
    headerKey: "exclusive_knife_card_header",
    descriptionHeaderKey: "exclusive_knife_card_description_header",
    metaKey: "exclusive_knife_card_meta",
    descriptionTextKey: "exclusive_knife_card_description_text",
    flags: ["ch"]
  }
];

const getProductsByCategory = (category) => {
  switch(category) {
    case "standard":
      return standardProducts;
    case "premium":
      return premiumProducts;
    case "exclusive":
      return exclusiveProducts;
    default:
      return [];
  }
};

const getProductById = (id) => {
  return [...exclusiveProducts].find(product => product.id === id);
};

const getProductByName = (name) => {
  return [...exclusiveProducts].find(product => product.name === name);
};

export {
  standardProducts,
  premiumProducts,
  exclusiveProducts,
  getProductsByCategory,
  getProductById,
  getProductByName
}; 