import React from 'react';
import { Button, Divider, Header, Icon, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SuccessStep = ({ 
  formData, 
  category, 
  onNext 
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const { 
    firstName, 
    lastName, 
    addressline1, 
    addressline2, 
    zipcode, 
    city, 
    country 
  } = formData;

  const handleContinue = () => {
    onNext();
  };

  return (
    <div className='Container_Modal'>
      <div className='Container'>
        <Header size='large'>{t('present_will_be_delivered_soon')}</Header>
        <Icon name='gift' size='massive' color='orange' />
        <div id='br_replacer' />
        {t('present_will_be_delivered_to')}:
        <div id='br_replacer' />
        <div id='br_replacer' />
        {firstName} {lastName} <br />
        {addressline1} <br />
        {addressline2 && (
          <>
            {addressline2} <br />
          </>
        )}
        {zipcode} {city} <br />
        {country}
      </div>
      
      <div>
        <Label attached='top left' size='large' color='orange'>{category.charAt(0).toUpperCase() + category.slice(1)}</Label>
      </div>
      
      <Divider />

      <div className='Container_small_middle'>
        <Button size='large' positive onClick={handleContinue}>Okay</Button>
      </div>
    </div>
  );
};

const FinalStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const handleGivePresent = () => {
    navigate('/');
  };

  return (
    <div className='Container_Modal'>
      <div className='Container'>
        <Header size='huge'>{t('make_happy')}</Header>
        <Header size='large'>{t('make_happy2')}</Header>
      </div>

      <div className='Container'>
        <Button size='huge' positive onClick={handleGivePresent}>
          {t('give_present')}
        </Button>
      </div>
    </div>
  );
};

const InvalidCodeStep = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const handleReturnHome = () => {
    navigate('/');
  };

  return (
    <div className='Container_Modal'>
      <div className='Container'>
        <Icon name='exclamation circle' color='red' size='massive'/>
        <div id='br_replacer' />
        <div id='br_replacer' />
        <Header size='large'>{t('code_used')}</Header>
      </div>

      <div id='br_replacer' />

      <div className='Container'>
        <Button size='huge' onClick={handleReturnHome}>
          {t('return_to_home_page')}
        </Button>
      </div>
    </div>
  );
};

export { SuccessStep, FinalStep, InvalidCodeStep }; 