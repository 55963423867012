import React, { useCallback } from 'react';
import { Form, Button, Divider, Message, Container, Header, Step, Icon, Flag } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FormField from './FormField';
import useForm from '../hooks/useForm';
import { BirthdayDatePicker, isAtLeast18 } from './BirthdayDatePicker';

// Email validation helper
const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const DeliveryForm = ({
  selectedProduct,
  onBack,
  onContinue,
  category,
  deviceMobile
}) => {
  const { t } = useTranslation();
  
  // Prüfe, ob Wein ausgewählt wurde - korrekte Identifizierung über den Namen
  const isWineSelected = selectedProduct === "Wine";
  
  // Form validation
  const validateForm = useCallback((values) => {
    const errors = {};
    
    if (!values.firstName) errors.firstName = true;
    if (!values.lastName) errors.lastName = true;
    if (!values.addressline1) errors.addressline1 = true;
    if (!values.zipcode || values.zipcode.length !== 5) errors.zipcode = true;
    if (!values.city) errors.city = true;
    if (values.email && !isEmail(values.email)) errors.email = true;
    if (!values.termsAccepted) errors.termsAccepted = true;
    
    // Altersverifikation für Wein
    if (isWineSelected) {
      if (!values.birthDate || !values.birthDate.day || !values.birthDate.month || !values.birthDate.year) {
        errors.birthDate = true;
      } else if (!isAtLeast18(values.birthDate.day, values.birthDate.month, values.birthDate.year)) {
        errors.birthDate = t('must_be_18');
      }
    }
    
    return errors;
  }, [isWineSelected, t]);
  
  // Initialize form with default values
  const initialValues = {
    firstName: '',
    lastName: '',
    addressline1: '',
    addressline2: '',
    zipcode: '',
    city: '',
    country: 'Deutschland',
    email: '',
    mobil: '',
    birthDate: null,
    termsAccepted: false
  };
  
  const {
    values,
    errors,
    handleChange,
    validateForm: runValidation,
    setValues
  } = useForm(initialValues, validateForm);
  
  const handleBirthdayChange = useCallback((birthdayData) => {
    setValues(prev => ({
      ...prev,
      birthDate: birthdayData,
      // Für die API-Kompatibilität das formatierte Datum auch in das alte Feld schreiben
      birthday: birthdayData.formatted
    }));
  }, [setValues]);
  
  const handleSubmit = useCallback(() => {
    const isValid = runValidation();
    
    if (isValid) {
      onContinue(values);
    }
  }, [values, runValidation, onContinue]);
  
  const handleToggleTerms = useCallback(() => {
    setValues(prev => ({
      ...prev,
      termsAccepted: !prev.termsAccepted
    }));
  }, [setValues]);
  
  const errorPointingDirection = useCallback(() => {
    if (deviceMobile) {
      return t('must_agree_terms');
    } else {
      return {
        content: t('must_agree_terms'),
        // pointing: 'left',
      };
    }
  }, [deviceMobile, t]);

  return (
    <div className='Container_Modal'>
      {deviceMobile ? 
        <Step.Group unstackable widths={3} size='mini'>
          <Step>
            <Icon name='gift' color='orange'/>
          </Step>
          <Step active>
            <Icon name='truck' color='orange'/>
          </Step>
          <Step disabled>
            <Icon name='info' color='orange'/>
          </Step>
        </Step.Group> : 
        <Step.Group unstackable widths={3} size='mini'>
          <Step>
            <Icon name='gift' color='orange'/>
            <Step.Content>
              <Step.Title>1. {t('choose_present')}</Step.Title>
            </Step.Content>
          </Step>
          <Step active>
            <Icon name='truck' color='orange'/>
            <Step.Content>
              <Step.Title>2. {t('delivery_details')}</Step.Title>
            </Step.Content>
          </Step>
          <Step disabled>
            <Icon name='info' color='orange'/>
            <Step.Content>
              <Step.Title>3. {t('confirmation')}</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      }

      <Divider />
      
      <Form>
        <Form.Group>
          <FormField
            name="firstName"
            width={5}
            required
            label={t('first_name')}
            placeholder={t('first_name')}
            value={values.firstName}
            onChange={handleChange}
            error={errors.firstName}
          />
          <FormField
            name="lastName"
            width={5}
            required
            label={t('last_name')}
            placeholder={t('last_name')}
            value={values.lastName}
            onChange={handleChange}
            error={errors.lastName}
          />
        </Form.Group>
        <Form.Group>
          <FormField
            name="addressline1"
            width={5}
            required
            label={t('address_line') + ' 1'}
            placeholder={t('address_line') + ' 1'}
            value={values.addressline1}
            onChange={handleChange}
            error={errors.addressline1}
          />
          <FormField
            name="addressline2"
            width={5}
            label={t('address_line') + ' 2'}
            placeholder={t('address_line') + ' 2'}
            value={values.addressline2}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <FormField
            name="zipcode"
            width={2}
            required
            label={t('zip')}
            placeholder={t('zip')}
            value={values.zipcode}
            onChange={handleChange}
            error={errors.zipcode}
          />
          <FormField
            name="city"
            width={3}
            required
            label={t('city')}
            placeholder={t('city')}
            value={values.city}
            onChange={handleChange}
            error={errors.city}
          />
        </Form.Group>
        <FormField
          name="country"
          width={5}
          required
          disabled
          label={t('country')}
          placeholder={t('country')}
          value={values.country}
          onChange={handleChange}
        />
      </Form>
      
      {deviceMobile ?
        <Message attached floating compact warning>
          <Icon><Flag name='germany'></Flag></Icon>
          {t('only_ship_to_germany')}
        </Message>
        :
        <div className='Container_Modal_Message_mobile'>
          <Message attached floating compact warning>
            <Icon><Flag name='germany'></Flag></Icon>
            {t('only_ship_to_germany')}
          </Message>
        </div>
      }

      <div style={{ marginTop: '20px' }}></div>

      <div className='Container_small_left'>
        <Container textAlign='left'>
          {t('please_note_our')}{' '}
          <a target="_blank" rel="noopener noreferrer" href='/data_protection'>
            {t('data_protection_declaration')}
          </a>
          {' '}{t('and')}{' '} 
          <a target="_blank" rel="noopener noreferrer" href='/terms'>
            {t('terms')}
          </a>.
        </Container>
      </div>
      
      <div id='br_replacer' />

      <Form as="div">  
        <Form.Checkbox
          label={t('agree_terms')}
          checked={values.termsAccepted}
          onClick={handleToggleTerms}
          error={errors.termsAccepted ? errorPointingDirection() : false}
          style={{ whiteSpace: 'normal', display: 'inline-block', lineHeight: '1.4', width: '100%', border: 'none' }}
        />
      </Form>

      <Divider />

      <Form>
        <Form.Group>
          <FormField
            name="email"
            width={4}
            label='E-Mail'
            placeholder='E-Mail'
            value={values.email}
            onChange={handleChange}
            error={errors.email}
          />
          <FormField
            name="mobil"
            width={3}
            label='Phone'
            placeholder='Phone'
            value={values.mobil}
            onChange={handleChange}
          />
          <BirthdayDatePicker
            value={values.birthDate}
            onChange={handleBirthdayChange}
            error={errors.birthDate}
            required={isWineSelected}
            deviceMobile={deviceMobile}
          />
        </Form.Group>
      </Form>

      <div id='br_replacer' />
      <div className='Container_small_left'>
        <Container textAlign='left'>
          <Header size='tiny' color='red'>* {t('mandatory_field')}</Header>
        </Container>
      </div>

      <Divider />

      <div className='Container_small_right'>
        <Button onClick={onBack}>
          {t('back')}
        </Button>
        <Button positive onClick={handleSubmit}>
          {t('continue')}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryForm; 