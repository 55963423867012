import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { Button, Icon, Divider, Modal, Form, Message, Header, Container, Grid, Card } from 'semantic-ui-react'
import { useHover } from "react-hookedup"
import { useTranslation } from "react-i18next"
import axios from "axios"

import './App.css'
import ImageSlider from './Carousel'
import PresentDelivery from "./PresentDelivery";
import MenuBar from "./MenuBar";
import Footer from "./components/Footer";

import ReCAPTCHA from "react-google-recaptcha";

import AOS from 'aos'
import "aos/dist/aos.css"
      
function exampleReducer(state, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { open_dimmer: true, dimmer: action.dimmer}
    case 'CLOSE_MODAL':
       return { open_dimmer: false }
    default: 
       throw new Error()
  }
}

const App = () => {
  const { t } = useTranslation()
  const [presentCode, setPresentCode] = useState("")
  const [presentCategory, setPresentCategory] = useState("")
  const [showCodeInputError, setshowCodeInputError] = useState(false)
  const [showRECAPTCHAError, setshowRECAPTCHAError] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)

  const [delivery_address_confirmed, setDelivery_address_confirmed] = useState(false)
  const [present_chosen, setPresentChosen] = useState("fullscreen")

  let navigate = useNavigate(); 
  const routeChange = (x) =>{ 
    let path = `${x}`;
    navigate(path)
  }

  const verifyToken = async (token) => {
    try{
    let response = await axios.post(`http://localhost:4000/verify-token`,{
       
    secret:process.env.REACT_APP_SECRET_KEY,
        token
    },console.log(token));
    return response.data;
    }catch(error){
    console.log("error ",error);
    }
  }

  const checkPresentCode = async (pk) => {
    try {
      setButtonLoading(true);
      let token = captchaRef.current.getValue();
      setshowCodeInputError(false);
      if(token){
            setshowRECAPTCHAError(false);
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                setPresentCategory(res.data.Item.category)
                setshowCodeInputError(false);
                setButtonLoading(false);
                dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' });
            }
            else {
              setshowCodeInputError(true);
              setButtonLoading(false);
              console.log('This code does not exist or has already been used.');
            }
          }else{
            setshowCodeInputError(true)
            setshowRECAPTCHAError(true);
            setButtonLoading(false);
          }
      
    }
    catch (err) {
      console.log(`An error has occurred: ${err}`);
      setshowCodeInputError(true);
      setButtonLoading(false);
    }
  }

  const { hovered, bind } = useHover();

  const [state, dispatch] = React.useReducer(exampleReducer, {
    open_dimmer: false,
    dimmer: undefined,
  })
  const { open_dimmer, dimmer } = state

  const [deviceMobile, setDeviceMobile] = useState(false);

  const captchaRef = useRef(null);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceMobile(true);
    } else {
      setDeviceMobile(false);
    }
    AOS.init({
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    })
  }, []);

  function errorMessage(){
    if (showRECAPTCHAError){
    return t('RECAPTCHA_error')
    } else if (showCodeInputError){
      return t('invalid_code')
    } else {
      return null
    }
  }

  const deliveryConfirmed = () => {
    console.log("Delivery address confirmed.")
    setDelivery_address_confirmed(true)
  }

  const presentChosen = () => {
    console.log("Present chosen.")
    setPresentChosen("large")
  }

  const presentChosenReverse = () => {
    console.log("Present chosen.")
    setPresentChosen("fullscreen")
  }

  return (
    <div className='App modern-ui'>
      <MenuBar active={'redeem'}/>

      <Container>
        {/* Main Content */}
        <Grid centered>
          <Grid.Column mobile={16} tablet={14} computer={12}>
            <Card fluid className="code-entry-card">
              <Card.Content>
                <div data-aos="fade-up">
                  <ImageSlider />
                </div>
                
                <div data-aos="fade-up" className="code-input-container">
                  <Form error={showCodeInputError}>
                    <Header as='h3' className="input-label">{t('enter_your_code')}</Header>
                    <Form.Input 
                      {...bind}
                      error={showCodeInputError}
                      focus={hovered ? true : false}
                      icon={hovered ? <Icon color='orange' name='gift' /> : <Icon color='black' name='gift' />}
                      size='large'
                      type='text'
                      placeholder={t('enter_code')}
                      value={presentCode}
                      onChange={(e) => setPresentCode(e.target.value)}
                      className="code-input"
                    />
                    <Message
                      error
                      content={errorMessage()}
                    />
                  </Form>
                </div>

                <div data-aos="fade-up" className="captcha-container">
                  <Header as='h3' className="input-label">{t('verify_with_captcha')}</Header>
                  <div className="captcha-wrapper">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                  </div>
                </div>
                
                <div data-aos="fade-up" className="redeem-actions">
                  <Button
                    color='orange'
                    size='large'
                    loading={buttonLoading}
                    onClick={() => {
                      checkPresentCode(presentCode)
                    }}
                    className="redeem-button"
                  >
                    <Icon name='gift' />
                    {t('choose_present')}
                  </Button>
                  
                  <Divider horizontal>
                    <Header as='h4'>{t('or')}</Header>
                  </Divider>
                  
                  <Button 
                    color='orange'
                    inverted
                    size='large'
                    onClick={() => routeChange('/')}
                    className="thank-you-button"
                  >
                    <Icon name='heart' />
                    {t('say_thank_you')}
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Modal
          closeIcon
          size={present_chosen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          dimmer={dimmer}
          open={open_dimmer}
          className="present-modal"
          onClose={() => {
            dispatch({ type: 'CLOSE_MODAL' })
            setPresentChosen("fullscreen")
            if (delivery_address_confirmed){
              navigate('/');
            }
          }}
        >
          <Modal.Content>
            <PresentDelivery presentCode={presentCode} category={presentCategory} deliveryConfirmed={deliveryConfirmed} presentChosen={presentChosen} presentChosenReverse={presentChosenReverse}/>
          </Modal.Content>
        </Modal>
      </Container>

      <Footer />
    </div>
  );
}

export default App;