import { useState, useCallback } from 'react';
import axios from 'axios';

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getClientIP = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      setLoading(false);
      return data.ip;
    } catch (err) {
      setError(`An error has occurred: ${err}`);
      setLoading(false);
      console.error(`An error has occurred: ${err}`);
      return null;
    }
  }, []);
  
  const saveRecipientInformation = useCallback(async (id, recipientData) => {
    try {
      setLoading(true);
      const current = new Date();
      const params = {
        ...recipientData,
        id,
        date: `${current.getDate()}.${current.getMonth()+1}.${current.getFullYear()}`
      };
      
      await axios.post(`${process.env.REACT_APP_RECIPIENT}/recipients/${id}`, params);
      setLoading(false);
      return true;
    } catch (err) {
      setError(`An error has occurred: ${err}`);
      setLoading(false);
      console.error(`An error has occurred: ${err}`);
      return false;
    }
  }, []);
  
  const validatePresentCode = useCallback(async (pk) => {
    try {
      setLoading(true);
      const params = {
        "PK": pk,
        "validStatus": 'invalid'
      };
      await axios.patch(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`, params);
      setLoading(false);
      return true;
    } catch (err) {
      setError(`An error has occurred: ${err}`);
      setLoading(false);
      console.error(`An error has occurred: ${err}`);
      return false;
    }
  }, []);
  
  const checkPresentCodeValidity = useCallback(async (pk) => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
      setLoading(false);
      return res.data.Item.validStatus === "valid";
    } catch (err) {
      setError(`An error has occurred: ${err}`);
      setLoading(false);
      console.error(`An error has occurred: ${err}`);
      return false;
    }
  }, []);
  
  return {
    loading,
    error,
    getClientIP,
    saveRecipientInformation,
    validatePresentCode,
    checkPresentCodeValidity
  };
};

export default useApi; 