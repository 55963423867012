import React, { useState } from 'react';
import { Grid, Button, Divider, Header, Segment, Step, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import SelectedPresent from '../SelectedPresent';

const ConfirmationStep = ({
  formData,
  selectedProduct,
  onBack,
  onConfirm,
  category,
  deviceMobile
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  
  const { 
    firstName, 
    lastName, 
    addressline1, 
    addressline2, 
    zipcode, 
    city, 
    country 
  } = formData;

  console.log("deviceMobile: ", deviceMobile);
  
  // Neue Funktion zum Verarbeiten des Bestätigungsklicks
  const handleConfirmClick = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='Container_Modal'>
      {deviceMobile ? 
        <Step.Group unstackable widths={3} size='mini'>
          <Step>
            <Icon name='gift' color='orange'/>
          </Step>
          <Step>
            <Icon name='truck' color='orange'/>
          </Step>
          <Step active>
            <Icon name='info' color='orange'/>
          </Step>
        </Step.Group> : 
        <Step.Group unstackable widths={3} size='mini'>
          <Step>
            <Icon name='gift' color='orange'/>
            <Step.Content>
              <Step.Title>1. {t('choose_present')}</Step.Title>
            </Step.Content>
          </Step>
          <Step>
            <Icon name='truck' color='orange'/>
            <Step.Content>
              <Step.Title>2. {t('delivery_details')}</Step.Title>
            </Step.Content>
          </Step>
          <Step active>
            <Icon name='info' color='orange'/>
            <Step.Content>
              <Step.Title>3. {t('confirmation')}</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      }

      <Divider />

      <Header as='h2' size='large'>
        <Header.Content>
          {t('confirm_delivery_address')}
          <Header.Subheader>{t('your_present')}</Header.Subheader>
        </Header.Content>
      </Header>

      <Grid stackable columns='three'>
        <Grid.Row stretched>
          <Grid.Column width={5.33}>
            <div className='Container_small' style={{ height: '100%' }}>
              <SelectedPresent present={selectedProduct} />
            </div>
          </Grid.Column>
          {deviceMobile ?
            <Grid.Column width={5.33}>
              <div className='Container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                <Icon color='orange' size='massive' name='long arrow alternate down'/>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  {t('expected_delivery')}
                </div>
              </div>
            </Grid.Column> :
            <Grid.Column width={5.33}>
              <div className='Container_Text_Center' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                <div id='br_replacer' />
                <div id='br_replacer' />
                <Icon color='orange' size='massive' name='long arrow alternate right'/>
                {t('expected_delivery')}
              </div>
            </Grid.Column>
          }
          <Grid.Column width={5.33}>
            <div className='Container_small' style={{ height: '100%' }}> 
              <Segment padded color='orange' size='massive' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Header size='tiny'>{t('shipping_address')}</Header>
                {firstName} {lastName} <div id='br_replacer' />
                {addressline1} <div id='br_replacer' />
                {addressline2 && (
                  <>
                    {addressline2} <div id='br_replacer' />
                  </>
                )}
                {zipcode} {city} <div id='br_replacer' />
                {country}
              </Segment>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div id='br_replacer' />

      <Divider />

      <div className='Container_small_right'>
        <Button onClick={onBack} disabled={isLoading}>
          {t('back')}
        </Button>
        <Button 
          positive 
          onClick={handleConfirmClick}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationStep; 