import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Dropdown, Flag, Icon, Image, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import img from "./assets/Logo.jpg";
import img_mobile from "./assets/Logo_mobile.png";

const MenuBar = ({ active }) => {
  const { t } = useTranslation();
  const [currentLanguageCode, setActiveLanguageCode] = useState(cookies.get("i18next") || "en");
  const [deviceMobile, setDeviceMobile] = useState(false);

  const langOptions = [
    {
      key: "English",
      text: "EN",
      code: "en",
      image: <Flag name="gb" />,
    },
    {
      key: "Deutsch",
      text: "DE",
      code: "de",
      image: <Flag name="de" />,
    },
  ];

  const checkDevice = () => {
    if (window.innerWidth <= 950) {
      setDeviceMobile(true);
    } else {
      setDeviceMobile(false);
    }
  };

  useEffect(() => {
    checkDevice();
    window.addEventListener("resize", checkDevice);
    return () => window.removeEventListener("resize", checkDevice);
  }, []);

  const [activeItem, setActiveItem] = useState(active);

  let navigate = useNavigate();
  const routeChange = (x) => {
    let path = `${x}`;
    navigate(path);
  };

  return (
    <>
      {deviceMobile ? (
        <Menu fixed="top" borderless className="modern-navbar mobile-navbar">
          <Menu.Menu position="left">
            <Menu.Item 
              onClick={() => {
                routeChange("/");
                setActiveItem("buy");
              }}
              className="logo-container mobile-logo"
              style={{
                transition: 'transform 0.3s ease-in-out',
                backgroundColor: 'transparent'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
            >
              <Image src={img_mobile} size="small" />
            </Menu.Item>
          </Menu.Menu>
          
          <Menu.Menu position="right">
            <Dropdown 
              icon={<Icon name="language" size="large" color="orange" />} 
              item
              className="language-dropdown mobile-dropdown"
            >
              <Dropdown.Menu>
                {langOptions.map(({ key, text, code, image }) => (
                  <Dropdown.Item
                    key={key}
                    icon={image}
                    text={text}
                    onClick={() => {
                      i18next.changeLanguage(code);
                      setActiveLanguageCode(code);
                    }}
                    disabled={code === currentLanguageCode}
                    className="language-item"
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            
            <Dropdown 
              item 
              icon={<Icon name="bars" size="large" color="orange" />}
              className="menu-dropdown"
            >
              <Dropdown.Menu className="modern-dropdown-menu">
                <Dropdown.Item
                  onClick={() => {
                    routeChange("/");
                    setActiveItem("buy");
                  }}
                  active={activeItem === "buy"}
                  className="modern-dropdown-item"
                >
                  <Icon name="cart" color={activeItem === "buy" ? "orange" : "grey"} />
                  <span className={activeItem === "buy" ? "active-text" : ""}>{t("buy_codes")}</span>
                </Dropdown.Item>
                
                <Dropdown.Item
                  onClick={() => {
                    navigate("/redeem");
                    setActiveItem("redeem");
                  }}
                  active={activeItem === "redeem"}
                  className="modern-dropdown-item"
                >
                  <Icon name="gift" color={activeItem === "redeem" ? "orange" : "grey"} />
                  <span className={activeItem === "redeem" ? "active-text" : ""}>{t("redeem_code")}</span>
                </Dropdown.Item>
                
                <Dropdown.Item
                  onClick={() => {
                    navigate("/how_it_works");
                    setActiveItem("how_it_works");
                  }}
                  active={activeItem === "how_it_works"}
                  className="modern-dropdown-item"
                >
                  <Icon name="question" color={activeItem === "how_it_works" ? "orange" : "grey"} />
                  <span className={activeItem === "how_it_works" ? "active-text" : ""}>{t("how_it_works")}</span>
                </Dropdown.Item>
                
                <Dropdown.Divider />
                
                <Dropdown.Item
                  onClick={() => {
                    window.open('https://app.presentolo.com', '_blank');
                  }}
                  className="modern-dropdown-item b2b-dropdown-item"
                >
                  <Icon name="handshake" color="red" />
                  <span className="b2b-text">{t("to_b2b")}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      ) : (
        <div className="navbar-container">
          <Menu fixed="top" borderless className="modern-navbar desktop-navbar">
            <div className="desktop-menu-container">
              <Menu.Item
                onClick={() => {
                  routeChange("/");
                  setActiveItem("buy");
                }}
                className="logo-container"
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  backgroundColor: 'transparent'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.backgroundColor = 'transparent';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.backgroundColor = 'transparent';
                }}
              >
                <Image src={img} size="small" />
              </Menu.Item>
              
              <div className="nav-links">
                <Menu.Item
                  name="buy"
                  active={activeItem === "buy"}
                  onClick={() => {
                    routeChange("/");
                    setActiveItem("buy");
                  }}
                  className={`nav-item ${activeItem === "buy" ? "active-nav-item" : ""}`}
                >
                  <Icon name="cart" color={activeItem === "buy" ? "orange" : "grey"} />
                  {t("buy_codes")}
                </Menu.Item>
                
                <Menu.Item
                  name="redeem"
                  active={activeItem === "redeem"}
                  onClick={() => {
                    navigate("/redeem");
                    setActiveItem("redeem");
                  }}
                  className={`nav-item ${activeItem === "redeem" ? "active-nav-item" : ""}`}
                >
                  <Icon name="gift" color={activeItem === "redeem" ? "orange" : "grey"} />
                  {t("redeem_code")}
                </Menu.Item>
                
                <Menu.Item
                  name="how_it_works"
                  active={activeItem === "how_it_works"}
                  onClick={() => {
                    navigate("/how_it_works");
                    setActiveItem("how_it_works");
                  }}
                  className={`nav-item ${activeItem === "how_it_works" ? "active-nav-item" : ""}`}
                >
                  <Icon name="question" color={activeItem === "how_it_works" ? "orange" : "grey"} />
                  {t("how_it_works")}
                </Menu.Item>
                
                <Button
                  color="red"
                  onClick={() => {
                    window.open('https://app.presentolo.com', '_blank');
                  }}
                  className="b2b-button"
                >
                  <Icon name="handshake" />
                  {t('to_b2b')}
                </Button>
              </div>
              
              <div className="language-container">
                <Dropdown
                  trigger={currentLanguageCode === "de" 
                    ? <Flag name="de" className="lang-flag" />
                    : <Flag name="gb" className="lang-flag" />
                  }
                  compact
                  className="language-dropdown desktop"
                >
                  <Dropdown.Menu>
                    {langOptions.map(({ key, text, code, image }) => (
                      <Dropdown.Item
                        key={key}
                        icon={image}
                        text={text}
                        onClick={() => {
                          i18next.changeLanguage(code);
                          setActiveLanguageCode(code);
                        }}
                        disabled={code === currentLanguageCode}
                        className="language-item"
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Menu>
        </div>
      )}
    </>
  );
};

export default MenuBar;
