import './App.css'
import React, { useState, useEffect } from "react"
import { Button, Menu, Icon, Header, Transition } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

import Cards_BuyCode from "./Cards_BuyCode"
import MenuBar from './MenuBar'
import Footer from './components/Footer'

import AOS from 'aos'
import "aos/dist/aos.css"

const BuyCode = () => {
    const { t } = useTranslation()

    const [activeCategory, setActiveCategory] = useState('exclusive')
    const [deviceMobile, setDeviceMobile] = useState(false)
    const [visible, setVisible] = useState(true)

    const checkDevice = () => {
        if (window.innerWidth <= 950) {
            setDeviceMobile(true)
        } else {
            setDeviceMobile(false)
        }
    }

    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        })
        checkDevice()
        window.addEventListener("resize", checkDevice)
        return () => window.removeEventListener("resize", checkDevice)
    }, [])

    const handleCategoryChange = (category) => {
        if (category === activeCategory) return
        
        setVisible(false)
        setTimeout(() => {
            setActiveCategory(category)
            setTimeout(() => {
                setVisible(true)
            }, 50)
        }, 300)
    }

    const getPriceLink = () => {
        switch(activeCategory) {
            case 'exclusive':
                return "https://sowl.co/p/dWq9E"
            case 'premium':
                return "https://sowl.co/p/gGc3E"
            case 'standard':
                return "https://sowl.co/p/QHjvF"
            default:
                return "https://sowl.co/p/QHjvF"
        }
    }

    const getPriceText = () => {
        switch(activeCategory) {
            case 'exclusive':
                return `${t('exclusive_buy_code_now_for')} €100,-`
            case 'premium':
                return `${t('premium_buy_code_now_for')} €50,-`
            case 'standard':
                return `${t('standard_buy_code_now_for')} €20,-`
            default:
                return `${t('standard_buy_code_now_for')} €20,-`
        }
    }

    const renderStars = (count) => {
        return Array(count).fill().map((_, i) => (
            <Icon key={i} name='star' size={deviceMobile ? 'large' : 'big'} />
        ))
    }

    const renderCategoryItem = (name, starCount) => {
        const isActive = activeCategory === name
        return (
            <Menu.Item 
                className={`category-item ${isActive ? 'active-category' : ''}`}
                name={name} 
                active={isActive} 
                onClick={() => handleCategoryChange(name)}
            >
                <Header 
                    color={isActive ? 'orange' : null}
                    style={{ textTransform: 'capitalize', marginBottom: '8px' }}
                >
                    {name}
                </Header>
                <div className="star-container">
                    {renderStars(starCount)}
                </div>
            </Menu.Item>
        )
    }

    return (
        <div className='App modern-ui'>
            <MenuBar active={'buy'}/>

            <div className={deviceMobile ? 'Container_zero_padding_not_center' : 'Container_big_padding'}>
                <div id='br_replacer' />

                <div className={deviceMobile ? '' : 'Container_small'}>
                    <Menu 
                        size='large' 
                        borderless 
                        icon='labeled' 
                        className='category-menu'
                    >
                        <Menu.Menu>
                            {renderCategoryItem('exclusive', 3)}
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            {renderCategoryItem('premium', 2)}
                        </Menu.Menu>
                        <Menu.Menu position='right'>
                            {renderCategoryItem('standard', 1)}
                        </Menu.Menu>
                    </Menu>
                </div>

                <Transition visible={visible} animation='scale' duration={400}>
                    <div>
                        <Cards_BuyCode category={activeCategory} />
                    </div>
                </Transition>

                <div className='floating-purchase-container'>
                    <a target="_blank" rel="noopener noreferrer" href={getPriceLink()}>
                        <Button
                            className="purchase-button"
                            color='orange'
                            size={deviceMobile ? 'large' : 'huge'}
                        >
                            {getPriceText()}
                        </Button>
                    </a>
                </div>
            </div>
                            
            <Footer />
        </div>
    )
} 

export default BuyCode