import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Menu, Dropdown, Flag, Icon, Header, Input, Divider, List, Modal, Step, Form, Message, Image, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import cookies from 'js-cookie'
import axios from "axios"
import { useParams } from "react-router-dom";

import MenuBar from "../MenuBar";

const BlankPage = () => {
    const { t } = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [presentCategory, setPresentCategory] = useState("")
    const [code_valid, setCode_valid] = useState(false);

    const langOptions = [
        {
          key: 'English',
          text: 'EN',
          code: 'en',
          image: <Flag name='gb' />
        },
        {
          key: 'Deutsch',
          text: 'DE',
          code: 'de',
          image: <Flag name='de' />
        }
      ]

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    const [activeItem, setActiveItem] = useState('')

    let navigate = useNavigate(); 
    const routeChange = (x) =>{ 
      let path = `${x}`; 
      navigate(path);
    }

    return(
    <>
        <div className="App">
            <div className="Container">
                <Icon loading name="circle notch" size="massive" color="orange"/>
            </div>
        </div>
    </>
    )
}

export default BlankPage;