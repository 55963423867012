import React from 'react';
import { Form } from 'semantic-ui-react';

const FormField = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  type = 'text',
  width,
  required = false,
  disabled = false,
  error = false
}) => {
  return (
    <Form.Input
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      type={type}
      width={width}
      required={required}
      disabled={disabled}
      error={error}
    />
  );
};

export default FormField; 