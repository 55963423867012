import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

// Import custom hooks
import useApi from './hooks/useApi';

// Import components
import ProductSelection from './components/ProductSelection';
import DeliveryForm from './components/DeliveryForm';
import ConfirmationStep from './components/ConfirmationStep';
import { SuccessStep, FinalStep, InvalidCodeStep } from './components/SuccessStep';

const PresentDeliveryRefactored = ({ presentCode, category, deliveryConfirmed, presentChosen, presentChosenReverse }) => {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const navigate = useNavigate();
  
  // API hooks
  const { 
    loading, 
    error, 
    getClientIP, 
    saveRecipientInformation,
    validatePresentCode,
    checkPresentCodeValidity 
  } = useApi();
  
  // Progress state
  const [progress, setProgress] = useState(0);
  
  // Selected product state
  const [selectedProduct, setSelectedProduct] = useState("");
  
  // Form data state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    addressline1: "",
    addressline2: "",
    zipcode: "",
    city: "",
    country: "Deutschland",
    email: "",
    mobil: "",
    birthday: "",
    clientIP: ""
  });
  
  // Device detection
  const [deviceMobile, setDeviceMobile] = useState(false);
  
  // Detect device on mount
  useEffect(() => {
    const checkDevice = async () => {
      // Bestehender UA-Check
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
          navigator.userAgent
        ) || window.innerWidth <= 768 // Fügt eine Prüfung der Bildschirmbreite hinzu
      ) {
        setDeviceMobile(true);
      } else {
        setDeviceMobile(false);
      }
      
      // Get client IP
      const ip = await getClientIP();
      setFormData(prev => ({ ...prev, clientIP: ip }));
    };
    
    checkDevice();

    // Reagiere auf Fenstergrößenänderungen
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDeviceMobile(true);
      } else {
        setDeviceMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getClientIP]);
  
  // Send email notification
  const sendEmail = useCallback(() => {
    const templateParams = {
      code: presentCode,
      category: category,
      product: selectedProduct,
      firstName: formData.firstName,
      lastName: formData.lastName,
      addressline1: formData.addressline1,
      addressline2: formData.addressline2,
      zipcode: formData.zipcode,
      city: formData.city,
      country: formData.country
    };
    
    emailjs.send('service_m94q71o', 'template_49w93k6', templateParams, 'oDnmn9WLRHUpvjmYU')
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
        console.log('FAILED...', error);
      });
  }, [presentCode, category, selectedProduct, formData]);
  
  // Handle product selection
  const handleProductSelect = useCallback((productName) => {
    setSelectedProduct(productName);
    presentChosen();
    setProgress(1);
  }, [presentChosen]);
  
  // Handle back to product selection
  const handleBackToProducts = useCallback(() => {
    presentChosenReverse();
    setProgress(0);
  }, [presentChosenReverse]);
  
  // Handle form submission
  const handleFormSubmit = useCallback((data) => {
    setFormData(prev => ({
      ...prev,
      ...data
    }));
    setProgress(2);
  }, []);
  
  // Handle confirmation and process order
  const handleConfirmOrder = useCallback(async () => {
    const isValid = await checkPresentCodeValidity(presentCode);
    
    if (isValid) {
      // Save recipient information
      await saveRecipientInformation(presentCode, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        addressline1: formData.addressline1,
        addressline2: formData.addressline2,
        zipcode: formData.zipcode,
        city: formData.city,
        country: formData.country,
        email: formData.email,
        mobil: formData.mobil,
        birthday: formData.birthday,
        ip: formData.clientIP
      });
      
      // Validate the present code (mark as used)
      await validatePresentCode(presentCode);
      
      // Send email notification
      sendEmail();
      
      // Update delivery confirmation
      deliveryConfirmed();
      
      // Go to success step
      setProgress(3);
    } else {
      // Code has already been used
      setProgress(10);
    }
  }, [
    presentCode, 
    formData, 
    checkPresentCodeValidity, 
    saveRecipientInformation, 
    validatePresentCode, 
    sendEmail, 
    deliveryConfirmed
  ]);
  
  // Render the appropriate step based on progress
  switch (progress) {
    case 0:
      return (
        <ProductSelection
          category={category}
          onContinue={handleProductSelect}
          deviceMobile={deviceMobile}
        />
      );
      
    case 1:
      return (
        <DeliveryForm
          selectedProduct={selectedProduct}
          onBack={handleBackToProducts}
          onContinue={handleFormSubmit}
          category={category}
          deviceMobile={deviceMobile}
        />
      );
      
    case 2:
      return (
        <ConfirmationStep
          formData={formData}
          selectedProduct={selectedProduct}
          onBack={() => setProgress(1)}
          onConfirm={handleConfirmOrder}
          category={category}
          deviceMobile={deviceMobile}
        />
      );
      
    case 3:
      return (
        <SuccessStep
          formData={formData}
          category={category}
          onNext={() => setProgress(4)}
        />
      );
      
    case 4:
      return <FinalStep />;
      
    case 10:
      return <InvalidCodeStep />;
      
    default:
      return null;
  }
};

export default PresentDeliveryRefactored; 