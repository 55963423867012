import React, {useState, useEffect} from "react"
import { Button, Header } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

import MenuBar from "./MenuBar";
import Footer from "./components/Footer";
import CookiePreferencesModal from "./CookiePreferencesModal";

const DataProtection = () => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);
    const [openCookieModal, setOpenCookieModal] = useState(false);

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    return (
        <>
            <div className="App">
                <MenuBar />
                
                {/* Spacer hinzufügen, um Platz für die fixed MenuBar zu schaffen */}
                <div style={{ height: '80px' }}></div>
                
                <Header size='huge' textAlign='center'>{t('data_protection')}</Header>
                
                <div className="Container">
                    <Button 
                        color="orange" 
                        size="medium" 
                        onClick={() => setOpenCookieModal(true)}
                    >
                        {t('update_cookies_preferences')}
                    </Button>
                    
                    <CookiePreferencesModal 
                        open={openCookieModal}
                        onClose={() => setOpenCookieModal(false)}
                        translations={t}
                    />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default DataProtection;