import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, List } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <div className="Container">
      <List centered bulleted horizontal link size='large'>
        <List.Item as='a' onClick={() => navigate('/terms')}>
          {t('terms')}
        </List.Item>
        <List.Item as='a' onClick={() => navigate('/data_protection')}>
          {t('data_protection')}
        </List.Item>
        <List.Item as='a' onClick={() => navigate('/imprint')}>
          {t('imprint')}
        </List.Item>
      </List>
      <div className="Container_middle">
        <Button target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/presentolo" circular color="linkedin" icon="linkedin" />
      </div>
    </div>
  );
};

export default Footer; 