import React, {useState, useEffect, useRef} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Icon, Header, Modal, Segment, Label, Message } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"
import axios from "axios"
import { useParams } from "react-router-dom";

import MenuBar from "../MenuBar";
import Footer from "../components/Footer";
import ErrorPage from "./ErrorPage";
import BlankPage from "./BlankPage";
import PresentDelivery from "../PresentDelivery";

import ReCAPTCHA from "react-google-recaptcha";

function exampleReducer(state, action) {
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open_dimmer: true, dimmer: action.dimmer}
      case 'CLOSE_MODAL':
         return { open_dimmer: false }
      default: 
         throw new Error()
    }
}

const AutomaticCodeRedeem = () => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [presentCategory, setPresentCategory] = useState("")
    const [buyer_name, setBuyer_name] = useState("")
    const [recipient_name, setRecipient_name] = useState("")
    const [custom_message, setCustom_message] = useState("")
    const [code_valid, setCode_valid] = useState(false);
    const [code_checked, setCode_checked] = useState(false);
    const [checkedCAPTCHA, setCheckedCAPTCHA] = useState(false);
    const [hideMessage, setHideMessage] = useState(true);

    const [delivery_address_confirmed, setDelivery_address_confirmed] = useState(false)
    const [present_chosen, setPresentChosen] = useState("fullscreen")

    const [errorMessageVisible, setErrorMessageVisible] = useState(true)

    const captchaRef = useRef(null);

    const [state, dispatch] = React.useReducer(exampleReducer, {
        open_dimmer: false,
        dimmer: undefined,
    })
    const { open_dimmer, dimmer } = state

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    let navigate = useNavigate(); 

    const checkPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                setPresentCategory(res.data.Item.category)
                setBuyer_name(res.data.Item.buyer_name)
                setRecipient_name(res.data.Item.recipient_name)
                setCustom_message(res.data.Item.custom_message)
                setCode_checked(true)
                setCode_valid(true)
            }
            else {
                setCode_valid(false)
                setCode_checked(true);
                console.log('This code does not exist or has already been used.');
            }
        }
        catch (err) {
            setCode_checked(true);
            setCode_valid(false)
            console.log(`An error has occurred: ${err}`);
        }
    }

    const checkHuman = () => {
        let token = captchaRef.current.getValue();
        if (token) {
            checkPresentCode(code)
            setCheckedCAPTCHA(true);
        } else {
            setHideMessage(false)
            setCheckedCAPTCHA(false)
        }
    }

    const doubleCheckPresentCode = async (pk) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_CHECK_CODE}/code/${pk}`);
            if (res.data.Item.validStatus === "valid") {
                dispatch({ type: 'OPEN_MODAL', dimmer: 'blurring' })
            } else {
                setErrorMessageVisible(false)
            }
        }
        catch (err){
            setErrorMessageVisible(false)
            console.log(`An error has occurred: ${err}`);
        }
    }

    const deliveryConfirmed = () => {
        console.log("Delivery address confirmed.")
        setDelivery_address_confirmed(true)
    }
    
    const presentChosen = () => {
        console.log("Present chosen.")
        setPresentChosen("large")
    }

    const presentChosenReverse = () => {
        console.log("Present chosen.")
        setPresentChosen("fullscreen")
    }

    const routeParams = useParams();
    const code = routeParams.code.slice(1, -1);

    if (checkedCAPTCHA === false){
        return (
            <>
                <div className="App">
                    <MenuBar />
                    
                    {/* Spacer hinzufügen, um Platz für die fixed MenuBar zu schaffen */}
                    <div style={{ height: '80px' }}></div>
                    
                    <div className="Container">
                        <Header size='large'>{t('RECAPTCHA')}</Header>

                        <br /><br />

                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                        
                        <br />

                        <Message hidden={hideMessage} error content={t('RECAPTCHA_error')} />

                        <br />

                        <Button circular color="orange" size="huge" onClick={() => checkHuman()}>
                            {t('verify')}
                        </Button>
                    </div>
                    
                    <br /><br /><br /><br />
                    
                    <Footer />
                </div>
            </>
        )
    }
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === false) {return (
        <BlankPage />
    )}
    
    if (checkedCAPTCHA === true && code_valid === false && code_checked === true) {return (
        <ErrorPage />
    )}

    if (checkedCAPTCHA === true && code_valid === true && code_checked === true) {return (
        <>
        <div className="App">
            <MenuBar />
            
            {/* Spacer hinzufügen, um Platz für die fixed MenuBar zu schaffen */}
            <div style={{ height: '80px' }}></div>
            
            <div className="Container">
                <div className="content-wrapper">
                    {custom_message !== undefined ? <Header size='large' textAlign='center'>{custom_message}</Header> :
                    buyer_name === "" ?
                    <Header size='large' textAlign='center'>{t('congratulations')}{recipient_name !== "" ? ", " + recipient_name :  null}! {t('congratulations_2')}{t('congratulations_3')}</Header>
                    :
                    <Header size='large' textAlign='center'>{t('congratulations')}{recipient_name !== "" ? ", " + recipient_name :  null}! {t('congratulations_2')} {t('from')} {buyer_name}{t('congratulations_3')}</Header>
                    }
                    
                    <div className="Container">
                        <Segment placeholder raised className="gift-icon-container">
                            <Header size='medium' textAlign='center'>{t('choose_present')}:</Header>
                            <div className="gift-icon-wrapper" onClick={() => doubleCheckPresentCode(code)}>
                                <Icon name="gift" size="massive" color="orange" className="gift-icon-pulse" />
                            </div>
                            <div className="code-label-container">
                                <Label basic size='large' color='orange' className="code-label">
                                    {t('your_code')} <b>{code}</b>
                                </Label>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>

            <Modal
                closeIcon
                size={present_chosen}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                dimmer={dimmer}
                open={open_dimmer}
                onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL' })
                    setPresentChosen("fullscreen")
                    if (delivery_address_confirmed){
                      navigate('/');
                    }
                }}
            >
                <Modal.Content>
                    <PresentDelivery presentCode={code} category={presentCategory} deliveryConfirmed={deliveryConfirmed} presentChosen={presentChosen} presentChosenReverse={presentChosenReverse}/>
                </Modal.Content>
            </Modal>
        </div>

        <Footer />
    </>
    )}
}

export default AutomaticCodeRedeem;