import React, {useState, useEffect} from "react"
import { useNavigate } from "react-router-dom";
import { Button, Image, Grid, Icon } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

import MenuBar from "./MenuBar";
import Footer from "./components/Footer";

import AOS from 'aos'
import "aos/dist/aos.css"

const HowItWorks = () => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1); // Für die FAQ-Accordion
      
    // Funktions-Deklaration für die Geräteprüfung
    const checkDevice = () => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            ) || window.innerWidth <= 950
        ) {
            setDeviceMobile(true);
        } else {
            setDeviceMobile(false);
        }
    };

    useEffect(() => {
        // Initial Check
        checkDevice();
        
        // Event-Listener für Fenstergrößenänderungen
        window.addEventListener("resize", checkDevice);
        
        // AOS Initialisierung mit besseren Parametern für UX
        AOS.init({
            disable: false, // Nicht für Mobilgeräte deaktivieren
            duration: 800, // Kürzere Dauer für bessere Reaktionsfähigkeit
            easing: "ease-out-cubic",
            once: true, // Animationen nur einmal ausführen
            offset: 50 // Früherer Beginn der Animation
        });
        
        // Event-Listener beim Unmounting entfernen
        return () => window.removeEventListener("resize", checkDevice);
    }, []);

    let navigate = useNavigate(); 
    
    // Optional: Debug-Ausgabe um den aktuellen Status zu sehen
    console.log("deviceMobile in HowItWorks:", deviceMobile);

    return (
        <>
            <div className="App">
                <MenuBar active={'how_it_works'}/>
                
                {/* Spacer hinzufügen, um Platz für die fixed MenuBar zu schaffen */}
                <div style={{ height: '80px' }}></div>
                
                {/* Hero-Bereich mit klarer Überschrift */}
                <div className="hero-section" style={{ textAlign: 'center', padding: '20px 0 40px' }}>
                    <h1 style={{ fontSize: deviceMobile ? '28px' : '36px', marginBottom: '15px', color: '#333' }}>
                        {t('how_it_works_main_title')}
                    </h1>
                    <p className="subtitle" style={{ fontSize: deviceMobile ? '16px' : '18px', color: '#666', maxWidth: '800px', margin: '0 auto 30px' }}>
                        {t('how_it_works_subtitle')}
                    </p>
                    <Image 
                        src={deviceMobile ? 
                            t('how_it_works_illustration_mobile') : 
                            t('how_it_works_illustration')
                        } 
                        size={deviceMobile ? 'large' : 'huge'} 
                        centered
                        className="hero-image"
                        data-aos="fade-up"
                    />
                </div>
                
                {/* Vorteile-Sektion */}
                <div className="benefits-section" style={{ 
                    background: '#f8f8f8', 
                    padding: '50px 20px',
                    margin: '20px 0'
                }} data-aos="fade-up">
                    <h2 style={{ 
                        textAlign: 'center', 
                        marginBottom: '40px', 
                        color: '#333',
                        fontSize: '28px'
                    }}>
                        {t('benefits_title')}
                    </h2>
                    <Grid columns={3} stackable container>
                        <Grid.Row>
                            <Grid.Column>
                                <div style={{ 
                                    textAlign: 'center', 
                                    padding: '20px',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <Icon name='gift' size='huge' color='orange' />
                                    <h3 style={{ margin: '20px 0', color: '#333', fontWeight: 'bold' }}>
                                        {t('benefit_1_title')}
                                    </h3>
                                    <p style={{ color: '#666' }}>
                                        {t('benefit_1_description')}
                                    </p>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ 
                                    textAlign: 'center', 
                                    padding: '20px',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <Icon name='truck' size='huge' color='orange' />
                                    <h3 style={{ margin: '20px 0', color: '#333', fontWeight: 'bold' }}>
                                        {t('benefit_2_title')}
                                    </h3>
                                    <p style={{ color: '#666' }}>
                                        {t('benefit_2_description')}
                                    </p>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div style={{ 
                                    textAlign: 'center', 
                                    padding: '20px',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    <Icon name='smile' size='huge' color='orange' />
                                    <h3 style={{ margin: '20px 0', color: '#333', fontWeight: 'bold' }}>
                                        {t('benefit_3_title')}
                                    </h3>
                                    <p style={{ color: '#666' }}>
                                        {t('benefit_3_description')}
                                    </p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                
                {/* FAQ-Sektion */}
                <div className="faq-section" style={{ 
                    padding: '60px 20px', 
                    maxWidth: '900px', 
                    margin: '20px auto',
                    background: '#fff',
                    borderRadius: '12px',
                    boxShadow: '0 5px 25px rgba(0,0,0,0.05)'
                }} data-aos="fade-up">
                    <h2 style={{ 
                        textAlign: 'center', 
                        marginBottom: '40px', 
                        color: '#333',
                        fontSize: '32px',
                        position: 'relative',
                        paddingBottom: '15px'
                    }}>
                        {t('common_questions')}
                        <div style={{
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '80px',
                            height: '3px',
                            background: '#ff7c39',
                            borderRadius: '3px'
                        }}></div>
                    </h2>
                    
                    <div style={{ margin: '0 auto' }}>
                        {[1, 2, 3, 4, 5].map((index) => (
                            <div 
                                key={index}
                                style={{
                                    marginBottom: '16px',
                                    background: activeIndex === index-1 ? '#fff8f3' : '#fff',
                                    borderRadius: '8px',
                                    border: '1px solid',
                                    borderColor: activeIndex === index-1 ? '#ff7c39' : '#eee',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease'
                                }}
                            >
                                <div
                                    onClick={() => setActiveIndex(activeIndex === index-1 ? -1 : index-1)}
                                    style={{
                                        padding: '20px 25px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        fontWeight: '600',
                                        fontSize: '17px',
                                        color: activeIndex === index-1 ? '#ff7c39' : '#333',
                                        transition: 'all 0.3s ease',
                                        background: activeIndex === index-1 ? '#fff8f3' : '#fff'
                                    }}
                                >
                                    <span>{t(`faq_question_${index}`)}</span>
                                    <Icon 
                                        name={activeIndex === index-1 ? 'angle up' : 'angle down'} 
                                        color={activeIndex === index-1 ? 'orange' : 'grey'}
                                        size="large"
                                    />
                                </div>
                                <div 
                                    style={{
                                        maxHeight: activeIndex === index-1 ? '300px' : '0',
                                        overflow: 'hidden',
                                        transition: 'max-height 0.4s ease, padding 0.3s ease',
                                        padding: activeIndex === index-1 ? '5px 25px 25px' : '0 25px',
                                        color: '#666',
                                        lineHeight: '1.6',
                                        fontSize: '16px',
                                        background: activeIndex === index-1 ? '#fff8f3' : '#fff'
                                    }}
                                >
                                    <p style={{ margin: '10px 0' }}>{t(`faq_answer_${index}`)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                    <div style={{ 
                        textAlign: 'center', 
                        marginTop: '40px',
                        paddingTop: '20px',
                        borderTop: '1px solid #eee'
                    }}>
                        <p style={{ 
                            fontSize: '17px', 
                            color: '#666',
                            marginBottom: '20px'
                        }}>
                            {t('faq_more_questions')}
                        </p>
                        <Button
                            as='a' 
                            href="mailto:sales@presentolo.com"
                            color='orange'
                            basic
                            content={t('contact_us')}
                            icon='mail'
                            labelPosition='left'
                            style={{ 
                                borderRadius: '30px',
                                boxShadow: '0 4px 12px rgba(255, 124, 57, 0.15)'
                            }}
                        />
                    </div>
                </div>
                
                {/* Call-to-Action hinzufügen - Styling wie in App.js */}
                <div className="cta-section" style={{ 
                    textAlign: 'center', 
                    padding: '50px 20px',
                    background: '#f5f5f5',
                    margin: '30px 0'
                }} data-aos="fade-up">
                    <h3 style={{ 
                        marginBottom: '30px', 
                        color: '#333', 
                        fontSize: '24px',
                        fontWeight: 'bold'
                    }}>
                        {t('ready_to_start')}
                    </h3>
                    <div className="cta-buttons" style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        gap: '20px', 
                        flexWrap: 'wrap' 
                    }}>
                        <Button 
                            className="redeem-button"
                            color='orange' // Orange Button wie in App.js
                            size='large' 
                            onClick={() => navigate('/')}
                            icon="cart" 
                            content={t('buy_code')} 
                            labelPosition="left"
                            style={{ margin: '5px' }}
                        />
                        <Button 
                            className="thank-you-button"
                            color='orange'
                            inverted // Invertiert wie in App.js
                            size='large' 
                            onClick={() => navigate('/redeem')}
                            icon="gift" 
                            content={t('redeem_code')} 
                            labelPosition="left"
                            style={{ margin: '5px' }}
                        />
                    </div>
                </div>
            </div>

            {/* Mobile-spezifisches Styling */}
            {deviceMobile && (
                <style jsx="true">{`
                    .Container text {
                        padding: 0 15px;
                        font-size: 16px;
                        line-height: 1.6;
                    }
                    
                    .process-step {
                        margin-bottom: 30px;
                        padding: 15px;
                        border-radius: 8px;
                        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
                    }
                    
                    .hero-section h1 {
                        font-size: 24px;
                        margin-bottom: 10px;
                    }
                    
                    .hero-section .subtitle {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                `}</style>
            )}

            <Footer />
        </>
    )
}

export default HowItWorks;