import React, {useState, useEffect} from "react"
import { Header, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

import MenuBar from "./MenuBar";
import Footer from "./components/Footer";

const Imprint = () => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    return (
        <>
            <div className="App modern-ui">
                <MenuBar />
                
                <div style={{ position: "relative", marginTop: "80px" }}>
                    <Header size='huge' textAlign='center'>{t('imprint')}</Header>
                    
                    <br /><br />

                    <Container text textAlign='left'>
                        <p>
                            {t('company_name')} <br />
                            {t('company_street')} <br />
                            {t('company_city')} <br />
                            {t('company_country')} <br />

                            <br />

                            {t('phone')}: {t('phone_number')} <br />
                            {t('fax')}: {t('fax_number')} <br />
                            {t('email')}: <a href={`mailto:${t('email_address')}`}>{t('email_address')}</a> <br />

                            <br />

                            {t('represented_by')}: {t('representative_name')} <br />

                            <br />

                            {t('register_entry')}: <br />
                            {t('register_court')}: {t('court_name')} <br />
                            {t('register_number')}: {t('reg_number')} <br />
                            {t('vat')}: {t('vat_number')}
                        </p>

                        <Header as='h3'>{t('disclaimer_title')}</Header>
                        
                        <Header as='h4'>{t('disclaimer_section_a_title')}</Header>
                        <p>{t('disclaimer_section_a_content')}</p>
                        
                        <Header as='h4'>{t('disclaimer_section_b_title')}</Header>
                        <p>{t('disclaimer_section_b_content')}</p>
                        
                        <Header as='h4'>{t('disclaimer_section_c_title')}</Header>
                        <p>{t('disclaimer_section_c_content')}</p>
                        
                        <Header as='h4'>{t('disclaimer_section_d_title')}</Header>
                        <p>{t('disclaimer_section_d_content')}</p>
                    </Container>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Imprint;