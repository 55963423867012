import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Card, Header, Image, Flag, Container } from 'semantic-ui-react'
import cookies from 'js-cookie'
import useHover from 'react-use-hover';

import AOS from 'aos'
import "aos/dist/aos.css"

const Cards_BuyCode = ({ category }) => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);

    const [isHovering1, hoverProps1] = useHover();
    const [isHovering2, hoverProps2] = useHover();
    const [isHovering3, hoverProps3] = useHover();
    const [isHovering4, hoverProps4] = useHover();

    useEffect(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
          navigator.userAgent
        )
      ) {
        setDeviceMobile(true);
      } else {
        setDeviceMobile(false);
      }
      AOS.init({
        disable: "phone",
        duration: 500,
        easing: "ease-out-cubic",
      })
    }, []);

    if (category === 'exclusive') return (

            
            <div className='Container_Modal'>
            <Card.Group itemsPerRow={4} centered doubling stackable className="equal-width-cards">
                <Card {...hoverProps1} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/dWq9E" raised id={isHovering1 ? 'card-selected' : false}>
                    <Image src={isHovering1 ? t('wine_exclusive_hover') : "./images/wine_exclusive.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering1 ? 'card-selected' : false}>{t('exclusive_wine_card_header')}</Card.Header>
                        <Card.Description id={isHovering1 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('exclusive_wine_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering1 ? 'card-selected' : false}>{t('exclusive_wine_card_meta')} <Flag name='it' /></Card.Meta>
                        <Card.Description id={isHovering1 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('exclusive_wine_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('exclusive_wine_card_description_bullet1')}</li>
                                    <li>{t('exclusive_wine_card_description_bullet2')}</li>
                                    <li>{t('exclusive_wine_card_description_bullet3')}</li>
                                    <li>{t('exclusive_wine_card_description_bullet4')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/dWq9E" raised id={isHovering2 ? 'card-selected' : false}>
                    <Image src={isHovering2 ? t('zippo_exclusive_hover') : "./images/zippo_exclusive.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering2 ? 'card-selected' : false}>{t('exclusive_zipper_card_header')}</Card.Header>
                        <Card.Description id={isHovering2 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('exclusive_zipper_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering2 ? 'card-selected' : false}>{t('exclusive_zipper_card_meta')} <Flag name='us' /></Card.Meta>
                        <Card.Description id={isHovering2 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('exclusive_zipper_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('exclusive_zipper_card_description_bullet1')}</li>
                                    <li>{t('exclusive_zipper_card_description_bullet2')}</li>
                                    <li>{t('exclusive_zipper_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/dWq9E" raised id={isHovering3 ? 'card-selected' : false}>
                    <Image src={isHovering3 ? t('knife_exclusive_hover') : "./images/knife_exclusive.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering3 ? 'card-selected' : false}>{t('exclusive_knife_card_header')}</Card.Header>
                        <Card.Description id={isHovering3 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('exclusive_knife_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering3 ? 'card-selected' : false}>{t('exclusive_knife_card_meta')} <Flag name='ch' /></Card.Meta>
                        <Card.Description id={isHovering3 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('exclusive_knife_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('exclusive_knife_card_description_bullet1')}</li>
                                    <li>{t('exclusive_knife_card_description_bullet2')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>
                </Card.Group>
            </div>
            
    )

    else if (category === 'premium') return (

            <div className='Container_Modal'>
            <Card.Group itemsPerRow={4} centered doubling stackable className="equal-width-cards">
                <Card {...hoverProps1} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/gGc3E" raised id={isHovering1 ? 'card-selected' : false}>
                    <Image src={isHovering1 ? t('balsamico-olive_premium_hover') : "./images/balsamico-olive_premium.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering1 ? 'card-selected' : false}>{t('premium_balsamico_card_header')}</Card.Header>
                        <Card.Description id={isHovering1 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('premium_balsamico_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering1 ? 'card-selected' : false}>{t('premium_balsamico_card_meta')} <Flag name='gr' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={isHovering1 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('premium_balsamico_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('premium_balsamico_card_description_bullet1')}</li>
                                    <li>{t('premium_balsamico_card_description_bullet2')}</li>
                                    <li>{t('premium_balsamico_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/gGc3E" raised id={isHovering2 ? 'card-selected' : false}>
                    <Image src={isHovering2 ? t('parker_premium_hover') : "./images/parker_premium.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering2 ? 'card-selected' : false}>{t('premium_pen_card_header')}</Card.Header>
                        <Card.Description id={isHovering2 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('premium_pen_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering2 ? 'card-selected' : false}>{t('premium_pen_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={isHovering2 ? 'card-selected' : false}>
                            <Header id={isHovering2 ? 'card-selected' : false}></Header>
                            <Container textAlign='justified'>
                                {t('premium_pen_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('premium_pen_card_description_bullet1')}</li>
                                    <li>{t('premium_pen_card_description_bullet2')}</li>
                                    <li>{t('premium_pen_card_description_bullet3')}</li>
                                    <li>{t('premium_pen_card_description_bullet4')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/gGc3E" raised id={isHovering3 ? 'card-selected' : false}>
                    <Image src={isHovering3 ? t('coffee_premium_hover') : "./images/coffee_premium.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering3 ? 'card-selected' : false}>{t('premium_coffee_card_header')}</Card.Header>
                        <Card.Description id={isHovering3 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('premium_coffee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering3 ? 'card-selected' : false}>{t('premium_coffee_card_meta')} <Flag name='jm' /></Card.Meta>
                        <Card.Description id={isHovering3 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('premium_coffee_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('premium_coffee_card_description_bullet1')}</li>
                                    <li>{t('premium_coffee_card_description_bullet2')}</li>
                                    <li>{t('premium_coffee_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps4} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/gGc3E" raised id={isHovering4 ? 'card-selected' : false}>
                    <Image src={isHovering4 ? t('chocolate_premium_hover') : "./images/chocolate_premium.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering4 ? 'card-selected' : false}>{t('premium_choco_card_header')}</Card.Header>
                        <Card.Description id={isHovering4 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('premium_choco_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering4 ? 'card-selected' : false}>{t('premium_choco_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={isHovering4 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('premium_choco_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('premium_choco_card_description_bullet1')}</li>
                                    <li>{t('premium_choco_card_description_bullet2')}</li>
                                    <li>{t('premium_choco_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>
                </Card.Group>
            </div>

    )

    else if (category === 'standard') return (

            <div className='Container_Modal'>
            <Card.Group itemsPerRow={4} centered doubling stackable className="equal-width-cards">
                    <Card {...hoverProps1} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/QHjvF" raised id={isHovering1 ? 'card-selected' : false}>
                    <Image src={isHovering1 ? t('chocodrink_standard_hover') : "./images/chocodrink_standard.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering1 ? 'card-selected' : false}>{t('standard_chocodrink_card_header')}</Card.Header>
                        <Card.Description id={isHovering1 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('standard_chocodrink_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering1 ? 'card-selected' : false}>{t('standard_chocodrink_card_meta')} <Flag name='fr' /></Card.Meta>
                        <Card.Description id={isHovering1 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('standard_chocodrink_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('standard_chocodrink_card_description_bullet1')}</li>
                                    <li>{t('standard_chocodrink_card_description_bullet2')}</li>
                                    <li>{t('standard_chocodrink_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps2} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/QHjvF" raised id={isHovering2 ? 'card-selected' : false}>
                    <Image src={isHovering2 ? t('tea_standard_hover') : "./images/tea_standard.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering2 ? 'card-selected' : false}>{t('standard_tee_card_header')}</Card.Header>
                        <Card.Description id={isHovering2 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('standard_tee_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering2 ? 'card-selected' : false}>{t('standard_tee_card_meta')} <Flag name='cn' /></Card.Meta>
                        <Card.Description id={isHovering2 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('standard_tee_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('standard_tee_card_description_bullet1')}</li>
                                    <li>{t('standard_tee_card_description_bullet2')}</li>
                                    <li>{t('standard_tee_card_description_bullet3')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps3} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/QHjvF" raised id={isHovering3 ? 'card-selected' : false}>
                    <Image src={isHovering3 ? t('magnets_standard_hover') : "./images/magnets_standard.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering3 ? 'card-selected' : false}>{t('standard_magnet_card_header')}</Card.Header>
                        <Card.Description id={isHovering3 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('standard_magnet_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering3 ? 'card-selected' : false}>{t('standard_magnet_card_meta')} <Flag name='de' /></Card.Meta>
                        <Card.Description id={isHovering3 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('standard_magnet_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('standard_magnet_card_description_bullet1')}</li>
                                    <li>{t('standard_magnet_card_description_bullet2')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card {...hoverProps4} target="_blank" rel="noopener noreferrer" href="https://sowl.co/p/QHjvF" raised id={isHovering4 ? 'card-selected' : false}>
                    <Image src={isHovering4 ? t('licorice_standard_hover') : "./images/licorice_standard.jpg"} />
                    <Card.Content>
                        <Card.Header id={isHovering4 ? 'card-selected' : false}>{t('standard_licorice_card_header')}</Card.Header>
                        <Card.Description id={isHovering4 ? 'card-selected-productname' : 'card-unselected-productname'}>
                            {t('standard_licorice_card_description_header')}
                        </Card.Description>
                        <Card.Meta id={isHovering4 ? 'card-selected' : false}>{t('standard_licorice_card_meta')} <Flag name='dk' /><Flag name='it' /></Card.Meta>
                        <Card.Description id={isHovering4 ? 'card-selected' : false}>
                            <Container textAlign='justified'>
                                {t('standard_licorice_card_description_text')}
                                <br /><br />
                                <ul>
                                    <li>{t('standard_licorice_card_description_bullet1')}</li>
                                    <li>{t('standard_licorice_card_description_bullet2')}</li>
                                </ul>
                            </Container>
                        </Card.Description>
                    </Card.Content>
                </Card>
                </Card.Group>
            </div>

    )
}

export default Cards_BuyCode