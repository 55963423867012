import React, {useState, useEffect} from "react"
import { Icon, Header, Container } from 'semantic-ui-react'
import { useTranslation } from "react-i18next"

import MenuBar from "../MenuBar";
import Footer from "../components/Footer";

const ErrorPage = () => {
    const { t } = useTranslation()

    const [deviceMobile, setDeviceMobile] = useState(false);

    useEffect(() => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
        )
    ) {
        setDeviceMobile(true);
    } else {
        setDeviceMobile(false);
    }
    }, []);

    return(
    <>
        <div className="App">

            <MenuBar />
            
            {/* Spacer to accommodate the fixed MenuBar */}
            <div style={{ height: '80px' }}></div>

            <div className="Container">
                <Icon name="warning sign" color="orange" size="massive" />

                <Header size='huge' textAlign='center'>{t('page_not_exists')}</Header>
                
                <br />

                <Container>
                    <Header size='large' textAlign='center'>{t('return_to_homepage_1')}<a target="_blank" rel="noopener noreferrer" href='/'>{t('return_to_homepage_2')}</a>{t('return_to_homepage_3')}<a href= "mailto:info@presentolo.com">{t('return_to_homepage_4')}</a>{t('return_to_homepage_5')}</Header>
                </Container>
            </div>
        </div>

        <br /><br /><br /><br /><br /><br /><br /><br />

        <Footer />
    </>
    )
}

export default ErrorPage;