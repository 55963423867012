import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button } from 'semantic-ui-react';

const CookiePreferencesModal = ({ open, onClose, translations }) => {
  // Initialize with default values
  const [cookieSettings, setCookieSettings] = useState({
    necessary: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    if (open) {
      try {
        const savedSettings = localStorage.getItem('cookieConsent');
        if (savedSettings) {
          const parsed = JSON.parse(savedSettings);
          setCookieSettings(parsed);
        }
      } catch (error) {
        console.error('Error loading cookie settings:', error);
      }
    }
  }, [open]);

  const handleSaveSettings = () => {
    const settings = {
      ...cookieSettings,
      necessary: true // ensure necessary is always true
    };
    localStorage.setItem('cookieConsent', JSON.stringify(settings));
    
    // Apply cookie settings
    if (settings.analytics) {
      // Analytics-Cookies setzen
    }
    if (settings.marketing) {
      // Marketing-Cookies setzen
    }
    
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="small"
      className="cookie-preferences-modal"
    >
      <Modal.Header>{translations('cookie_settings') || 'Cookie Settings'}</Modal.Header>
      <Modal.Content>
        <div className="cookie-settings">
          <div className="cookie-setting-item">
            <div className="cookie-setting-content">
              <h4>{translations('cookie_necessary') || 'Necessary'}</h4>
              <p>{translations('cookie_necessary_description') || 'Required for the website to function properly.'}</p>
            </div>
            <Checkbox
              checked={cookieSettings.necessary}
              disabled
            />
          </div>
          
          <div className="cookie-setting-item">
            <div className="cookie-setting-content">
              <h4>{translations('cookie_analytics') || 'Analytics'}</h4>
              <p>{translations('cookie_analytics_description') || 'Help us improve by tracking anonymous usage data.'}</p>
            </div>
            <Checkbox
              checked={cookieSettings?.analytics ?? false}
              onChange={(e, data) => setCookieSettings(prev => ({
                ...prev,
                analytics: data.checked
              }))}
            />
          </div>
          
          <div className="cookie-setting-item">
            <div className="cookie-setting-content">
              <h4>{translations('cookie_marketing') || 'Marketing'}</h4>
              <p>{translations('cookie_marketing_description') || 'Allow us to provide more relevant content and offers.'}</p>
            </div>
            <Checkbox
              checked={cookieSettings?.marketing ?? false}
              onChange={(e, data) => setCookieSettings(prev => ({
                ...prev,
                marketing: data.checked
              }))}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          {translations('cancel') || 'Cancel'}
        </Button>
        <Button 
          color="orange"
          onClick={handleSaveSettings}
        >
          {translations('cookie_save_settings') || 'Save Settings'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CookiePreferencesModal;
