import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

// Hilfsfunktion zur Validierung des Alters (18+)
const isAtLeast18 = (day, month, year) => {
  if (!day || !month || !year) return false;
  
  const birthday = new Date(year, month - 1, day); // JS months are 0-based
  const today = new Date();
  
  let age = today.getFullYear() - birthday.getFullYear();
  const monthDiff = today.getMonth() - (birthday.getMonth());
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  
  return age >= 18;
};

// Hilfsfunktion um zu prüfen, ob ein Jahr ein Schaltjahr ist
const isLeapYear = (year) => {
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
};

// Funktion, um die maximale Anzahl der Tage für einen Monat zu ermitteln
const getDaysInMonth = (month, year) => {
  if (!month || !year) return 31;
  
  const daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  
  // Februar im Schaltjahr hat 29 Tage
  if (month === 2 && isLeapYear(year)) {
    return 29;
  }
  
  return daysInMonth[month];
};

const BirthdayDatePicker = ({ 
  value, 
  onChange, 
  error, 
  required,
  deviceMobile
}) => {
  const { t } = useTranslation();
  const [day, setDay] = useState(value?.day || '');
  const [month, setMonth] = useState(value?.month || '');
  const [year, setYear] = useState(value?.year || '');
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState(31);
  
  // Update days in month when month or year changes
  useEffect(() => {
    if (month && year) {
      const maxDays = getDaysInMonth(month, year);
      setDaysInSelectedMonth(maxDays);
      
      // If current day is higher than max days in new month, adjust it
      if (day > maxDays) {
        setDay(maxDays);
        if (onChange) {
          onChange({
            day: maxDays, 
            month: month, 
            year: year,
            formatted: `${maxDays < 10 ? '0' + maxDays : maxDays}.${month < 10 ? '0' + month : month}.${year}`
          });
        }
      }
    }
  }, [month, year, day, onChange]);
  
  // Generate options for days based on selected month and year
  const generateDayOptions = useCallback(() => {
    const days = [];
    for (let i = 1; i <= daysInSelectedMonth; i++) {
      days.push({
        key: `day-${i}`,
        text: i < 10 ? `0${i}` : `${i}`,
        value: i
      });
    }
    return days;
  }, [daysInSelectedMonth]);
  
  // Generate options for months with names
  const generateMonthOptions = useCallback(() => {
    const months = [
      { key: 'month-1', text: t('january'), value: 1 },
      { key: 'month-2', text: t('february'), value: 2 },
      { key: 'month-3', text: t('march'), value: 3 },
      { key: 'month-4', text: t('april'), value: 4 },
      { key: 'month-5', text: t('may'), value: 5 },
      { key: 'month-6', text: t('june'), value: 6 },
      { key: 'month-7', text: t('july'), value: 7 },
      { key: 'month-8', text: t('august'), value: 8 },
      { key: 'month-9', text: t('september'), value: 9 },
      { key: 'month-10', text: t('october'), value: 10 },
      { key: 'month-11', text: t('november'), value: 11 },
      { key: 'month-12', text: t('december'), value: 12 }
    ];
    return months;
  }, [t]);
  
  // Generate options for years
  const generateYearOptions = useCallback(() => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      years.push({
        key: `year-${i}`,
        text: `${i}`,
        value: i
      });
    }
    return years.sort((a, b) => b.value - a.value); // Neueste Jahre zuerst
  }, []);
  
  const handleDayChange = (e, { value }) => {
    setDay(value);
    if (onChange) {
      onChange({
        day: value, 
        month: month, 
        year: year,
        formatted: `${value < 10 ? '0' + value : value}.${month < 10 ? '0' + month : month}.${year}`
      });
    }
  };
  
  const handleMonthChange = (e, { value }) => {
    setMonth(value);
    if (onChange) {
      onChange({
        day: day, 
        month: value, 
        year: year,
        formatted: `${day < 10 ? '0' + day : day}.${value < 10 ? '0' + value : value}.${year}`
      });
    }
  };
  
  const handleYearChange = (e, { value }) => {
    setYear(value);
    if (onChange) {
      onChange({
        day: day, 
        month: month, 
        year: value,
        formatted: `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${value}`
      });
    }
  };
  
  const dayOptions = generateDayOptions();
  const monthOptions = generateMonthOptions();
  const yearOptions = generateYearOptions();
  
  return (
    <div className="field">
      <label>
        {error ? <span style={{ color: '#9E3F3F' }}>{t('birthday')}</span> : t('birthday')}
        {required && <span style={{ color: 'red' }}> *</span>}
      </label>
      <div className={`ui input ${error ? 'error' : ''}`} style={{ display: 'flex', gap: '10px' }}>
        <Form.Select
          placeholder={t('day')}
          options={dayOptions}
          value={day}
          onChange={handleDayChange}
          style={{ minWidth: '80px' }}
          error={!!error}
        />
        <Form.Select
          placeholder={t('month')}
          options={monthOptions}
          value={month}
          onChange={handleMonthChange}
          style={{ minWidth: '140px' }}
          error={!!error}
        />
        <Form.Select
          placeholder={t('year')}
          options={yearOptions}
          value={year}
          onChange={handleYearChange}
          style={{ minWidth: '100px' }}
          error={!!error}
        />
      </div>
      {error && typeof error === 'string' && 
        <div 
          className="ui basic red pointing prompt label" 
          role="alert" 
          aria-atomic="true"
          style={{ 
            display: 'block',
            width: '100%',
            textAlign: 'left',
            marginTop: '1.0rem'
          }}
        >
          {error}
        </div>
      }
    </div>
  );
};

export { BirthdayDatePicker, isAtLeast18 }; 